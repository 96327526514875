import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: authBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_AUTH}/users`,
  }),
  tagTypes: ['userProfile'],
  endpoints: (build) => ({
    getUserProfile: build.query({
      query: (id) => `/user-profile/${id}`,
      providesTags: ['userProfile'],
    }),
    updateUserProfile: build.mutation({
      query(data) {
        const { id, d } = data;
        return {
          url: `/user-profile/${id}`,
          method: 'PUT',
          body: d,
        };
      },
      invalidatesTags: ['userProfile'],
    }),
    updateMyTaxData: build.mutation({
      query(data) {
        const { id, d } = data;
        return {
          url: `/user-fiscal-address/${id}`,
          method: 'PUT',
          body: d,
        };
      },
      invalidatesTags: ['userProfile'],
    }),
    updateMyBankAccount: build.mutation({
      query: ({ id, d }) => ({
        url: `/user-bank-account/${id}`,
        method: 'PUT',
        body: d,
      }),
      invalidatesTags: ['userProfile'],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUpdateMyTaxDataMutation,
  useUpdateMyBankAccountMutation,
} = userProfileApi;
