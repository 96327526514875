import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentEvent,
  setEventCommission,
  setEventId,
} from './event.slice';
import {
  useGetEventCommissionsDetailQuery,
  useGetEventCommissionsQuery,
  useGetEventDetailQuery,
  useGetEventQuery,
  useGetEventSummaryQuery,
  useGetEventTaxDateQuery,
} from './event.api';

const initialState = {
  event: {},
};

const useEvent = () => {
  const dispatch = useDispatch();
  const event = useSelector(selectCurrentEvent);

  const { data: taxData, isLoading: isLoadingTaxtData } = useGetEventTaxDateQuery(event?.id || '');

  const { data: eventData, isLoading: isLoadingEventData } = useGetEventQuery(event?.id || '');

  const { data: commissionData, isLoading: isLoadingCommissionData } = useGetEventCommissionsQuery(event?.id || '');

  const { data: summaryData, isLoading: isLoadingSummaryData } = useGetEventSummaryQuery(event?.id || '');

  const { data: detailData, isLoading: isLoadingDetailData} = useGetEventDetailQuery(event?.id || '');

  const { data: commissionDetailData, isLoading: isLoadingCommissionDetailData } = useGetEventCommissionsDetailQuery({
    eventId: event?.id,
    commission: event?.commission,
  });

  const setCurrentEvent = ({ id }) => {
    if (id) {
      dispatch(setEventId(id));
    }
  };

  const setCurrentEventCommissionDetail = (commission) => {
    if (commission) {
      dispatch(setEventCommission(commission));
    }
  };

  return {
    event: event || initialState,
    eventData,
    taxData,
    commissionData,
    summaryData,
    detailData,
    commissionDetailData,
    isLoadingTaxtData,
    isLoadingCommissionData,
    isLoadingSummaryData,
    isLoadingDetailData,
    isLoadingCommissionDetailData,
    isLoadingEventData,
    setCurrentEvent,
    setCurrentEventCommissionDetail,
  };
};

export default useEvent;
