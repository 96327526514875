// ----------------------------------------------------------------------

export default function Toolbar(theme) {
  return {
    MuiToolbar: {
      regular: {
        backgroundColor: "#ffff00",
        color: "#000000",
        height: "32px",
        minHeight: "32px",
        '@media (min-width: 600px)': {
          minHeight: "48px"
        }
      },
    },
  };
}
