import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const eventApi = createApi({
  reducerPath: 'eventApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['events'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getEvents: build.query({
      query: () => 'finance/v1/payments/events',
      providesTags: ['events'],
    }),
    getEvent: build.query({
      query: (eventId) => `finance/v1/payments/events/${eventId}`,
    }),
    getEventTaxDate: build.query({
      query: (eventId) => `finance/v1/payments/events/${eventId}/tax-data`,
      providesTags: ['events'],
    }),
    getEventSummary: build.query({
      query: (eventId) => `finance/v1/payments/events/${eventId}/summary`,
      providesTags: ['events'],
    }),
    getEventCommissions: build.query({
      query: (eventId) => `finance/v1/payments/events/${eventId}/simple-commissions`,
      providesTags: ['events'],
    }),
    getEventCommissionsDetail: build.query({
      query: ({eventId, commission}) => `finance/v1/payments/events/${eventId}/commissions/${commission}`,
      providesTags: ['events'],
    }),
    getEventDetail: build.query({
      query: (eventId) => `finance/v1/payments/events/${eventId}/detail`,
      providesTags: ['events'],
    }),
    postTransaction: build.mutation({
      query: (params) => ({
        url: `finance/v1/payments/events/${params.eventId}/transactions`,
        body: params.body,
        method: 'POST',
      }),
    }),
  }),
});


export const {
  useGetEventsQuery,
  useGetEventQuery,
  useGetEventTaxDateQuery,
  useGetEventCommissionsQuery,
  useGetEventCommissionsDetailQuery,
  useGetEventSummaryQuery,
  useGetEventDetailQuery,
  usePostTransactionMutation
} = eventApi;