import { ReactComponent as BalanceIcon } from '../../../assets/icons/antenna-bars.svg';

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      {
        title: 'Balance',
        path: '/dashboard/balance',
        icon: <BalanceIcon />,
        step: 'balance',
      },
      {
        title: 'Reglas por Organizador',
        path: '/dashboard/organizer',
        icon: <BalanceIcon />,
        step: 'organizer',
      },
      {
        title: 'Pendientes a dispersar',
        path: '/dashboard/dispersions',
        icon: <BalanceIcon />,
        step: 'dispersions',
      },
      {
        title: 'Dispersados',
        path: '/dashboard/dispersed',
        icon: <BalanceIcon />,
        step: 'dispersed',
      },
    ],
  },
];

export default navConfig;
