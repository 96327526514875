import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const closingApi = createApi({
  reducerPath: 'closingApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['closings'],
  endpoints: (build) => ({
    getClosings: build.query({
      query: (query) => `finance/v1/payments/closings${query}`,
    }),
    getClosing: build.query({
      query: (closingId) => `finance/v1/payments/closings/${closingId}`,
    }),
    getClosingsByBalance: build.query({
      query: (balanceID) => `finance/v1/payments/closings/balances/${balanceID}`,
    }),
  }),
});


export const {
  useGetClosingsQuery,
  useLazyGetClosingsQuery,
  useGetClosingQuery,
  useGetClosingsByBalanceQuery,
} = closingApi;