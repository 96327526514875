import { createSlice } from '@reduxjs/toolkit';
import { reHydrateStore } from '../utils/local-storage';

const slice = createSlice({
  name: 'event',
  initialState: reHydrateStore('event') || null,
  reducers: {
    setEventId: (state, { payload }) => ({ ...state, id: payload }),
    setEventCommission: (state, { payload }) => ({
      ...state,
      commission: payload,
    }),
  },
});

export const { setEventId } = slice.actions;
export const { setEventCommission } = slice.actions;

export default slice.reducer;

export const selectCurrentEvent = (state) => state.event;
