import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_AUTH}/users`,
  }),
  endpoints: (builder) => ({
    googleAuth: builder.mutation({
      query: (credetenials) => ({
        url: 'auth/sign-in-with-code',
        method: 'POST',
        body: credetenials,
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: `${process.env.REACT_APP_API_AUTH_URL}/users/auth/sign-in-v1`,
        method: 'POST',
        body: credentials,
      }),
      transformResponse: (response, meta, arg) => ({
        user: response.User,
        token: response.Access.AuthenticationResult?.AccessToken,
        roles: [],
        expiresIn: response.Access.AuthenticationResult?.ExpiresIn,
        idToken: response.Access.AuthenticationResult?.IdToken,
        refreshToken: response.Access.AuthenticationResult?.RefreshToken,
        tokenType: response.Access.AuthenticationResult?.TokenType,
        challengeName: response.Access.ChallengeName,
        challengeParameters: response.Access.ChallengeParameters,
        session: response.Access.Session,
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: 'auth/sign-up',
        method: 'POST',
        body: credentials,
      }),
    }),
    confirmEmail: builder.mutation({
      query: (credentials) => ({
        url: 'auth/confirm-sign-up',
        method: 'POST',
        body: credentials,
      }),
    }),
    confirmForgotPassword: builder.mutation({
      query: (credentials) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    resetForgottenPassword: builder.mutation({
      query: (credentials) => ({
        url: 'auth/confirm-forgot',
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: 'auth/change-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    resendEmailConfirmation: builder.mutation({
      query: (credentials) => ({
        url: 'auth/resend-confirmation',
        method: 'POST',
        body: credentials,
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: 'confirm-user/my-team',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useConfirmEmailMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useConfirmForgotPasswordMutation,
  useResendEmailConfirmationMutation,
  useResetForgottenPasswordMutation,
  useGoogleAuthMutation,
} = authApi;
