// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'none',
        },
        sizeLarge: {
          height: '45px',

        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedTerciary: {
          backgroundColor: theme.palette.terciary[300],
          border:  `0.5px solid ${theme.palette.terciary[400]} !important`,
          boxShadow: theme.customShadows.terciary,
          '&:hover': {
            backgroundColor: theme.palette.terciary[400],
          },
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        textTerciary: {
          color: `${theme.palette.terciary[400]}!important`,
          '&:hover': {
            backgroundColor: theme.palette.terciary[100],
          },
        },
        // outlined
        outlinedSecondary: {
          boxShadow: theme.customShadows.secondary,
          backgroundColor: theme.palette.secondary[50],
          border:  `0.5px solid ${theme.palette.secondary[100]} !important`,
          '&:hover': {
            border:  `0.5px solid ${theme.palette.secondary[100]} !important`,
          },
        },
        outlinedInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedDefault: {
          '&:hover': {
            backgroundColor: theme.palette.default[200],
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `1px solid ${theme.palette.grey[500_32]}`,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        colorInhert: {
          color: '#fff !important',
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        selected: {
          color: '#fff !important',
        },
        today: {
          backgroundColor: theme.palette.default[300]
          
        }
      }
    },
  }
}
