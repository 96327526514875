import { enUS, esES } from '@mui/material/locale';

export const TIME_ZONE_DEFAULT = 'America/Mexico_City';

export const STATUS_TO_UPDATE = [
  'CHOOSE_PAYMENT',
  'SUMMARY',
  'QUESTIONS',
  'LOGIN',
  'OTP',
];

export const PATHS = {
  HOME: {
    name: 'HOME',
    path: '/',
    label: 'Boletos',
    icon: 'mdi:ticket-confirmation-outline'
  },
  LOGIN: {
    name: 'LOGIN',
    path: '/guest',
    label: 'Registro',
    icon: 'ph:user'
  },
  SIGNIN: {
    name: 'SIGNIN',
    path: '/signin',
    label: 'Registro',
    icon: 'ph:user'
  },
  OTP: {
    name: 'OTP',
    path: '/code-verify',
    label: 'Código de verificación',
    icon: 'ph:user'
  },
  CHOOSE_PAYMENT: {
    name: 'CHOOSE_PAYMENT',
    path: '/payment',
    label: 'Método de pago',
    icon: 'material-symbols:credit-card-outline'
  },
  SUMMARY: {
    name: 'SUMMARY',
    path: '/confirm',
    label: 'Personaliza tus boletos',
    icon: 'material-symbols:edit'
  },
  QUESTIONS: {
    name: 'QUESTIONS',
    path: '/survey',
    label: 'Encuesta',
    icon: 'ri:survey-line'
  },
  SUCCESS: {
    name: 'SUCCESS',
    path: '/success',
    label: '¡Ya tienes tus boletos!',
    icon: 'material-symbols:celebration'
  },
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 54,
  MAIN_DESKTOP_HEIGHT: 64,
  DASHBOARD_DESKTOP_HEIGHT: 56,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: 'themeMode',
  themeLayout: 'themeLayout',
  themeStretch: 'themeStretch',
  themeContrast: 'themeContrast',
  themeDirection: 'themeDirection',
  themeColorPresets: 'themeColorPresets',
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];

export const defaultLang = allLangs[0]; // English
