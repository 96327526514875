import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './api/auth.api';
import { userProfileApi } from './api/userProfile.api';
import authReducer from './slices/auth.slice';
import configReducer from './slices/config.slice';
import eventReducer from './event/event.slice';
import closingReducer from './closing/closing.slice';
import balanceReducer from './balances/balance.slice';
import appStateReducer from './app-state/appState.slice';


import { localStorageMiddleware } from './utils/local-storage';
import { eventApi } from './event/event.api';
import { closingApi } from './closing/closing.api';
import { balanceApi } from './balances/balance.api';
import { dispersionsdApi } from './dispersions/dispersions.api';

export default configureStore({
  reducer: {
    auth: authReducer,
    config: configReducer,
    event: eventReducer,
    closing: closingReducer,
    balance: balanceReducer,
    appState: appStateReducer,
    [authApi.reducerPath]: authApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [closingApi.reducerPath]: closingApi.reducer,
    [balanceApi.reducerPath]: balanceApi.reducer,
    [dispersionsdApi.reducerPath]: dispersionsdApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      localStorageMiddleware(['auth', 'config', 'event', 'closing', 'balance']),
      authApi.middleware,
      userProfileApi.middleware,
      eventApi.middleware,
      closingApi.middleware,
      balanceApi.middleware,
      dispersionsdApi.middleware,
    ),
});

export { default as useEvent } from './event/useEvent';
export { default as useClosing } from './closing/useClosing';
export { default as useBalance } from './balances/useBalance';
export { default as useAppState } from './app-state/useAppState';
export { default as useDispersions } from './dispersions/useDispersions';

