import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const dispersionsdApi = createApi({
  reducerPath: 'dispersionsdApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['dispersions'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getDispersions: build.query({
      query: (queryParams) => `finance/v1/payments/dispersions${queryParams}`,
      providesTags: ['dispersions'],
    }),
    getClosing: build.query({
      query: ({ balanceID, queryParams }) =>
        `finance/v1/payments/balances/${balanceID}/closings${queryParams}`,
      providesTags: ['dispersions'],
    }),
    postClosing: build.mutation({
      query: ({ balanceID, params }) => ({
        url: `finance/v1/payments/balances/${balanceID}/closings`,
        body: params,
        method: 'POST',
      }),
    }),
    repostClosing: build.mutation({
      query: ({ balanceID, closingId }) => ({
        url: `finance/v1/payments/balances/${balanceID}/closings/${closingId}`,
        method: 'PUT',
      }),
    }),
    rejectPayment: build.mutation({
      query: ({ balanceId, paymentId }) => ({
        url: `finance/v1/payments/balances/${balanceId}/payments/${paymentId}/rejects`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useGetDispersionsQuery,
  useLazyGetDispersionsQuery,
  useLazyGetClosingQuery,
  usePostClosingMutation,
  useRejectPaymentMutation,
  useRepostClosingMutation,
} = dispersionsdApi;
