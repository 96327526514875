import { createSlice } from '@reduxjs/toolkit';
import { reHydrateStore } from '../utils/local-storage';

const slice = createSlice({
  name: 'balance',
  initialState: reHydrateStore('balance') || null,
  reducers: {
    setBalance: (state, { payload }) => ({...state, balance: payload}),
  },
});

export const { setBalance } = slice.actions;

export default slice.reducer;

export const selectCurrentBalance = (state) => state.balance.balance;