import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import RequireAuth from './require-auth';
import AdminLayout from '../layouts/admin';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../theme/components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'google',
          element: <GoogleAuth />,
        },
        {
          path: 'login',
          element: <LoginV2 />,
        },
        // {
        //   path: 'login/v2',
        //   element:<Login />,
        // },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/balance" replace />,
    },
    {
      path: '/dashboard',
      element: (
        <RequireAuth validateChangePass>
          <AdminLayout />
        </RequireAuth>
      ),
      children: [
        { element: <Navigate to="/dashboard/main" replace />, index: true },
        { path: 'main', element: <ComingSoon /> },
        { path: 'balance', element: <Balance /> },
        { path: 'balance/:id', element: <BalanceDetail /> },
        { path: 'organizer', element: <Organizer /> },
        { path: 'dispersed', element: <Dispersed /> },
        { path: 'dispersions', element: <Dispersions /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const LoginV2 = Loadable(lazy(() => import('../pages/auth/LoginV2')));

// Test
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Dashboard
const Balance = Loadable(lazy(() => import('../pages/balance/BalanceV2')));
const BalanceDetail = Loadable(lazy(() => import('../pages/balance/balance-detail/BalanceDetail')));
const GoogleAuth = Loadable(lazy(() => import('../pages/auth/GoogleAuth')));
const Organizer = Loadable(lazy(() => import('../pages/organizer/Organizer')));
const Dispersed = Loadable(lazy(() => import('../pages/dispersed/Dispersed')));
const Dispersions = Loadable(lazy(() => import('../pages/dispersions/Dispersions')));
