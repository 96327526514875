import { createSlice } from '@reduxjs/toolkit';
import { reHydrateStore } from '../utils/local-storage';

const slice = createSlice({
  name: 'closing',
  initialState: reHydrateStore('closing') || null,
  reducers: {
    setClosing: (state, { payload }) => ({...state, closing: payload}),
  },
});

export const { setClosing } = slice.actions;

export default slice.reducer;

export const selectCurrentClosing = (state) => state.closing?.closing;