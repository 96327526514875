import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentBalance, setBalance } from './balance.slice';
import { useGetBalanceQuery, useGetTransactionsQuery } from './balance.api';
import { selectCurrentEvent } from '../event/event.slice';


const initialState = {
  
};

const useBalance = () => {
  const dispatch = useDispatch();
  const event = useSelector(selectCurrentEvent);
  const { data: balanceData, refetch: refetchBalanceData } = useGetBalanceQuery(
    event?.id || '',
  );

  const { data: transactionsData,} = useGetTransactionsQuery();
  

  const transactions = transactionsData;
  const charges = transactionsData?.filter(x => x.charge === true).map((x) => ({id: x.id, label: x.name, value: x.id}));
  const bonus = transactionsData?.filter(x => x.charge === false).map((x) => ({id: x.id, label: x.name, value: x.id}));


  return {
    balance: balanceData || initialState,
    transactions,
    charges,
    bonus,
    refetchBalanceData
  };
};

export default useBalance;
