import React from 'react';
// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows.card,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          border: `1px solid ${theme.palette.default[200]}`, 
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          // backgroundColor: theme.palette.white
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.default[50]
        },
        action: {
          color: theme.palette.terciary[500],
          marginTop: '0 !important'
        },
        avatar: {
          color: theme.palette.secondary[600],
        },
        title: {
          fontSize: '20px !important',
          fontWeight: '400 !important',
        }
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
          // backgroundColor: theme.palette.white
        },
      },
    },
  };
}
