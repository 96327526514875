/* eslint-disable no-unreachable */
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ModalStack } from '@mattjennings/react-modal-stack';
import { PostHogProvider } from 'posthog-js/react';
import dayjs from 'dayjs';
import Router from './routes';
import ThemeProvider from './theme';
import RecaptchaProvider from './components/rechapta/RecaptchaProvider';
import ScrollToTop from './theme/components/ScrollToTop';
import { ProgressBarStyle } from './theme/components/ProgressBar';
import NotistackProvider from './theme/components/NotistackProvider';
import { MotionLazyContainer } from './theme/components/animate';
import "dayjs/locale/es";

dayjs.locale('es')

export default function App() {
  return (
      <MotionLazyContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <ThemeProvider>
              <NotistackProvider>
                <ModalStack>
                  <RecaptchaProvider>
                    <ProgressBarStyle />
                    <ScrollToTop />
                    <Router />
                  </RecaptchaProvider>
                </ModalStack>
              </NotistackProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </MotionLazyContainer>
  );
}
