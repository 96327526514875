import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const balanceApi = createApi({
  reducerPath: 'balanceApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['balances'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getBalance: build.query({
      query: (eventId) => `finance/v1/payments/balances/${eventId}/rules`,
      providesTags: ['balances'],
    }),
    getTransactions: build.query({
      query: () => `finance/v1/payments/transactions/types`,
    }),
    getBalanceRules: build.query({
      query: (balandeID) => `finance/v1/payments/rules/balance/${balandeID}`,
    }),
    postBalanceRules: build.mutation({
      query: ({balandeID, ...body}) => ({
        url: `finance/v1/payments/rules/balance/${balandeID}`,
        body,
        method: 'POST',
      }),
    }),
    getUserRules: build.query({
      query: (userId) => `finance/v1/payments/rules/user/${userId}`,
    }),
    postUserRules: build.mutation({
      query: ({userId, ...body}) => ({
        url: `finance/v1/payments/rules/user/${userId}`,
        body,
        method: 'POST',
      }),
    }),
    postOrganizerRules: build.mutation({
      query: (params) => ({
        url: `finance/v1/payments/organizers/${params.eventId}/rules`,
        body: params.body,
        method: 'POST',
      }),
    }),
    resetBalance: build.mutation({
      query: (balandeID) => ({
        url: `finance/v1/payments/rules/balance/${balandeID}/reset`,
        method: 'POST',
      }),
    }),
  }),
});


export const {
  useGetBalanceQuery,
  useGetTransactionsQuery,
  usePostOrganizerRulesMutation,
  useGetBalanceRulesQuery,
  usePostBalanceRulesMutation,
  useGetUserRulesQuery,
  useLazyGetUserRulesQuery,
  usePostUserRulesMutation,
  useResetBalanceMutation,
} = balanceApi;