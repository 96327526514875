import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const CONTENT = {
  main: '#FAFBFB',
  0: '#FAFBFB',
  50: '#FAFBFB',
  100: '#F0F1F3',
  200: '#DFE2E6',
  300: '#CFD4DA',
};

// SETUP COLORS
const DEFAULT = {
  main: '#354350',
  0: '#FAFBFB',
  50: '#FAFBFB',
  100: '#F0F1F3',
  200: '#DFE2E6',
  300: '#CFD4DA',
  400: '#C0C6CD',
  500: '#8996A2',
  600: '#697886',
  700: '#4D5D6C',
  800: '#354350',
  900: '#292F36',
};
const PRIMARY = {
  main: '#8282FF',
  0: '#FAFAFF',
  50: '#FAFAFF',
  100: '#E0E0FF',
  200: '#8282FF',
  300: '#5C5CFF',
  400: '#3131FF',
  500: '#0000FF',
  600: '#0202E9',
  700: '#0202CA',
  800: '#0000A7',
  900: '#00007C',
};
const SECONDARY = {
  main: '#CB37A7',
  0: '#FDF7FB',
  50: '#FDF7FB',
  100: '#FAEDF7',
  200: '#E69DD4',
  300: '#DE7FC7',
  400: '#D55DB8',
  500: '#CB37A7',
  600: '#B51A8F',
  700: '#A52184',
  800: '#961D78',
  900: '#791761',
};
const TERCIARY = {
  main: '#FF4D23',
  0: '#FFF8F6',
  50: '#FFF8F6',
  100: '#FFEFEB',
  200: '#FFA793',
  300: '#FF8D72',
  400: '#FF6F4D',
  500: '#FF4D23',
  600: '#E8441D',
  700: '#CF3C18',
  800: '#BF3818',
  900: '#B13213',
};
const SUCCESS = {
  main: '#17c964',
  0: '#e8faf0',
  50: '#e8faf0',
  100: '#d1f4e0',
  200: '#a2e9c1',
  300: '#74dfa2',
  400: '#45d483',
  500: '#17c964',
  600: '#12a150',
  700: '#0e793c',
  800: '#095028',
  900: '#095028',
};
const INFO = {
  main: '#0000FF',
  darker: '#0000FF',
  lighter: '#E0E0FF',
  0: '#e6f1fe',
  50: '#e6f1fe',
  100: '#cce3fd',
  200: '#99c7fb',
  300: '#d4d4d8',
  400: '#338ef7',
  500: '#006FEE',
  600: '#005bc4',
  700: '#004493',
  800: '#002e62',
  900: '#001731',
};

const WARNING = {
  main: '#f5a524',
  0: '#fefce8',
  50: '#fefce8',
  100: '#fdedd3',
  200: '#fbdba7',
  300: '#f9c97c',
  400: '#f7b750',
  500: '#f5a524',
  600: '#c4841d',
  700: '#936316',
  800: '#62420e',
  900: '#312107',
};
const DANGER = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  main: '#212B36',
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: 'linear-gradient(90deg, rgba(86, 38, 195, 0.80) 0%, rgba(182, 53, 150, 0.80) 54.62%, rgba(244, 52, 61, 0.80) 100.83%)',
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(DANGER.light, DANGER.main),
  text: 'linear-gradient(90deg, rgba(86, 38, 195, 0.80) 0%, rgba(182, 53, 150, 0.80) 5%, rgba(244, 52, 61, 0.80) 100.83%)',
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  content: {...CONTENT, contrastText: '#1E2429'},
  default: {...DEFAULT, contrastText: '#fff'},
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  terciary: { ...TERCIARY, contrastText: '#fff' },
  grey: { ...GREY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...DANGER, contrastText: '#fff' },
  common: { black: '#000', white: '#fff' },
  gradients: GRADIENTS,
  divider: DEFAULT[200],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: '#0202E9',
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  white: '#fff'
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: DEFAULT[900], secondary: DEFAULT[600], disabled: GREY[600], white: GREY[0], danger: '#F31260' },
    background: { paper: GREY[0], default: DEFAULT[50], neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: DEFAULT[700], disabled: GREY[400] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
