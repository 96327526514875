import { useDispatch, useSelector } from 'react-redux';
import { selectLoader, setLoader } from './appState.slice';

const useAppState = () => {
  const dispatch = useDispatch();
  const loader = useSelector(selectLoader);

  const startLoader = () => {
    dispatch(setLoader(true));
  };

  const stopLoader = () => {
    dispatch(setLoader(false));
  };

  return {
    loader,
    startLoader,
    stopLoader,
  };
};

export default useAppState;
