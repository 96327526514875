import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'appState',
  initialState: { loader: false },
  reducers: {
    setLoader: (state, { payload }) => ({ ...state, loader: payload }),
  },
});

export const { setLoader } = slice.actions;

export default slice.reducer;

export const selectLoader = (state) => state.appState.loader;
